import type { RouteRecordRaw } from "vue-router";

const foundationSystemAdminRoute: Array<RouteRecordRaw> = [
  {
    path: "/system-admin/foundation",
    name: "system-admin-foundation",
    component: () => import("@/views/sys_admin/foundation/FoundationList.vue"),
    meta: {
      pageTitle: "foundation",
      breadcrumbs: ['systemAdmin', 'foundation'],
    },
  },
  {
    path: "/system-admin/foundation/create",
    name: "system-admin-foundation-create",
    component: () => import("@/views/sys_admin/foundation/FoundationCreate.vue"),
    meta: {
      pageTitle: "foundation",
      breadcrumbs: ['systemAdmin', 'foundation', 'create'],
    },
  },
  {
    path: "/system-admin/foundation/detail",
    name: "system-admin-foundation-detail",
    component: () => import("@/views/sys_admin/foundation/FoundationDetail.vue"),
    meta: {
      pageTitle: "foundation",
      breadcrumbs: ['systemAdmin', 'foundation', 'detail'],
    },
  },
];

export default foundationSystemAdminRoute;
