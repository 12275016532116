import type { RouteRecordRaw } from 'vue-router'

const masterSystemAdminRoute: Array<RouteRecordRaw> = [
  {
    path: '/system-admin/class-program',
    name: 'system-admin-class-program',
    component: () => import('@/views/sys_admin/classProgram/ClassProgramList.vue'),
    meta: {
      pageTitle: 'classProgram',
      breadcrumbs: ['systemAdmin', 'classProgram'],
    },
  },
  {
    path: '/system-admin/class-program/create',
    name: 'system-admin-class-program-create',
    component: () => import('@/views/sys_admin/classProgram/ClassProgramCreate.vue'),
    meta: {
      pageTitle: 'classProgram',
      breadcrumbs: ['systemAdmin', 'classProgram', 'create'],
    },
  },
  {
    path: '/system-admin/class-program/update',
    name: 'system-admin-class-program-update',
    component: () => import('@/views/sys_admin/classProgram/ClassProgramUpdate.vue'),
    meta: {
      pageTitle: 'classProgram',
      breadcrumbs: ['systemAdmin', 'classProgram', 'update'],
    },
  },
//   Department
  {
    path: '/system-admin/department',
    name: 'system-admin-department',
    component: () => import('@/views/sys_admin/department/DepartmentList.vue'),
    meta: {
      pageTitle: 'department',
      breadcrumbs: ['systemAdmin', 'department'],
    },
  },
  {
    path: '/system-admin/department/create',
    name: 'system-admin-department-create',
    component: () => import('@/views/sys_admin/department/DepartmentCreate.vue'),
    meta: {
      pageTitle: 'department',
      breadcrumbs: ['systemAdmin', 'department', 'create'],
    },
  },
  {
    path: '/system-admin/department/update',
    name: 'system-admin-department-update',
    component: () => import('@/views/sys_admin/department/DepartmentUpdate.vue'),
    meta: {
      pageTitle: 'department',
      breadcrumbs: ['systemAdmin', 'department', 'update'],
    },
  },
//   Entrance
  {
    path: '/system-admin/entrance',
    name: 'system-admin-entrance',
    component: () => import('@/views/sys_admin/entrance/EntranceList.vue'),
    meta: {
      pageTitle: 'entrance',
      breadcrumbs: ['systemAdmin', 'entrance'],
    },
  },
  {
    path: '/system-admin/entrance/create',
    name: 'system-admin-entrance-create',
    component: () => import('@/views/sys_admin/entrance/EntranceCreate.vue'),
    meta: {
      pageTitle: 'entrance',
      breadcrumbs: ['systemAdmin', 'entrance', 'create'],
    },
  },
  {
    path: '/system-admin/entrance/update',
    name: 'system-admin-entrance-update',
    component: () => import('@/views/sys_admin/entrance/EntranceUpdate.vue'),
    meta: {
      pageTitle: 'entrance',
      breadcrumbs: ['systemAdmin', 'entrance', 'update'],
    },
  },
//   Institution Class
  {
    path: '/system-admin/institution-classes',
    name: 'system-admin-institution-classes',
    component: () => import('@/views/sys_admin/institutionClasses/InstitutionClassesList.vue'),
    meta: {
      pageTitle: 'institutionClasses',
      breadcrumbs: ['systemAdmin', 'institutionClasses'],
    },
  },
  {
    path: '/system-admin/institution-classes/create',
    name: 'system-admin-institution-classes-create',
    component: () => import('@/views/sys_admin/institutionClasses/InstitutionClassesCreate.vue'),
    meta: {
      pageTitle: 'institutionClasses',
      breadcrumbs: ['systemAdmin', 'institutionClasses', 'create'],
    },
  },
  {
    path: '/system-admin/institution-classes/update',
    name: 'system-admin-institution-classes-update',
    component: () => import('@/views/sys_admin/institutionClasses/InstitutionClassesUpdate.vue'),
    meta: {
      pageTitle: 'institutionClasses',
      breadcrumbs: ['systemAdmin', 'institutionClasses', 'update'],
    },
  },
//   Type Reception
  {
    path: '/system-admin/type-reception',
    name: 'system-admin-type-reception',
    component: () => import('@/views/sys_admin/typeReception/TypeReceptionList.vue'),
    meta: {
      pageTitle: 'typeReception',
      breadcrumbs: ['systemAdmin', 'typeReception'],
    },
  },
  {
    path: '/system-admin/type-reception/create',
    name: 'system-admin-type-reception-create',
    component: () => import('@/views/sys_admin/typeReception/TypeReceptionCreate.vue'),
    meta: {
      pageTitle: 'typeReception',
      breadcrumbs: ['systemAdmin', 'typeReception', 'create'],
    },
  },
  {
    path: '/system-admin/type-reception/update',
    name: 'system-admin-type-reception-update',
    component: () => import('@/views/sys_admin/typeReception/TypeReceptionUpdate.vue'),
    meta: {
      pageTitle: 'typeReception',
      breadcrumbs: ['systemAdmin', 'typeReception', 'update'],
    },
  },
//   Package Cost
  {
    path: '/system-admin/package-cost',
    name: 'system-admin-package-cost',
    component: () => import('@/views/sys_admin/packageCost/PackageCostList.vue'),
    meta: {
      pageTitle: 'packageCost',
      breadcrumbs: ['systemAdmin', 'packageCost'],
    },
  },
  {
    path: '/system-admin/package-cost/create',
    name: 'system-admin-package-cost-create',
    component: () => import('@/views/sys_admin/packageCost/PackageCostCreate.vue'),
    meta: {
      pageTitle: 'packageCost',
      breadcrumbs: ['systemAdmin', 'packageCost', 'create'],
    },
  },
  {
    path: '/system-admin/package-cost/update',
    name: 'system-admin-package-cost-update',
    component: () => import('@/views/sys_admin/packageCost/PackageCostUpdate.vue'),
    meta: {
      pageTitle: 'packageCost',
      breadcrumbs: ['systemAdmin', 'packageCost', 'update'],
    },
  },
]

export default masterSystemAdminRoute
